<template>
  <page-wrapper class="work-item">
    <h1 v-text="data.name" />

    <hr />

    <blockquote v-if="data.description" v-text="data.description" />

    <div class="content">
      <div
        v-for="(part, index) in data.content"
        :key="index"
        class="content-part"
        :class="part.type"
      >
        <lazy-image
          v-if="part.type == 'image'"
          :src="part.url"
          :aspect-ratio="part.aspectRatio"
          :alt="part.description"
        />
        <p v-else-if="part.type == 'paragraph'" v-text="part.text" />
        <blockquote v-else-if="part.type == 'quote'" v-text="part.text" />
        <p v-else-if="part.type == 'link'">
          <a
            v-text="part.text"
            :href="part.url"
            :target="part.target || '_self'"
          />
        </p>
        <div v-else-if="part.type == 'video'" class="video-frame">
          <iframe
            :src="`https://player.vimeo.com/video/${part.id}?color=ffffff&byline=0&portrait=0`"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>

    <template v-if="data.info">
      <h2>Details</h2>
      <hr />

      <dl class="info">
        <div v-for="(value, key) in data.info" :key="key" class="">
          <dt>{{ key }}:</dt>
          <dd>{{ value }}</dd>
        </div>
      </dl>
    </template>
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import LazyImage from "@/components/LazyImage";
import workList from "../work-list";

export default {
  metaInfo() {
    return {
      title: this.data.name,
    };
  },
  computed: {
    data() {
      return (
        workList.find((item) => {
          return item.id === this.$route.params.id;
        }) || {}
      );
    },
  },
  components: {
    PageWrapper,
    LazyImage,
  },
};
</script>

<style lang="scss" scoped>
.work-item {
  .content-part {
    & + .content-part {
      margin-top: 2em;
    }

    &.image + .content-part.image {
      margin-top: 4em;
    }
  }

  .video-frame {
    position: relative;
    padding-bottom: 100% / (16/9);

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .info {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    div {
      display: flex;
      flex-wrap: wrap;
      + div {
        margin-top: 0.5em;
      }
    }

    dt {
      flex-grow: 1;
    }

    dd {
      margin: 0;
      flex-shrink: 1;
      width: 70%;
      font-weight: 700;
      @media (max-width: 400px) {
        width: 100%;
      }
    }
  }
}
</style>
